import React from "react"
import ReactMarkdown from "react-markdown"

import SEO from "../components/SEO/SEO"
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'

const Article = ({ pageContext, location }) => {

	const { title, description } = pageContext

	return (
		<>
			<SEO
				title={ pageContext.title }
				pathname={ location.pathname } />
			<Breadcrumb pathname={ location.pathname } />
			<div className="wrapper">
				
				<div className="article">
					<div className="article__header-container"> 
						<h1 className="article__header"> { title }</h1>
					</div>
					
					<ReactMarkdown children={ description } className="article__text"/>

				</div>
			</div>
		</>
	)
}

export default Article